/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
	      jQuery(window).bind('scroll', function () {
			    if (jQuery(window).scrollTop() > jQuery('header .pre-header').outerHeight()) {
			      jQuery('header').addClass('fixed');
			    } else {
			      jQuery('header').removeClass('fixed');
			    }
				});
				function preventScroll(event) {
					event.preventDefault();
				}
				jQuery('.c-hamburger').on('click', function() {
					if (!jQuery(this).hasClass('is-active')) {
						jQuery(this).addClass('is-active');
						jQuery('header .nav-primary').fadeIn();
						jQuery('body').css('overflow', 'hidden').on('touchmove', preventScroll);
					} else {
						jQuery(this).removeClass('is-active');
						jQuery('header .nav-primary').fadeOut();
						jQuery('body').css('overflow', 'auto').off('touchmove', preventScroll);
					}
				});
				jQuery('.scroll-to-section').on('click', function() {
					jQuery('html, body').animate({ scrollTop: jQuery(this).offset().top - 86 }, 800);
				});
				function dateFromString(dateText) {
	        var year = parseInt(dateText.substring(0,4));
					var month = parseInt(dateText.substring(5,7));
					var day = parseInt(dateText.substring(8,10));
					return new Date(year, month-1, day);
        }
				function updatePrice() {
					var fromDateText = jQuery('#booking-form-sticky .value-from').val();
	        var year = parseInt(fromDateText.substring(0,4));
					var month = parseInt(fromDateText.substring(5,7));
					var day = parseInt(fromDateText.substring(8,10));
					var fromDate = new Date(year, month-1, day);
					var toDate = dateFromString(jQuery('#booking-form-sticky .value-to').val());
					var nights = (toDate.getTime() - fromDate.getTime()) / 24 / 60 / 60 / 1000;
	        
	        var url = 'https://www.secure-hotel-booking.com/';
	        url += lpdc.availpro_name;
	        url += '/' + lpdc.availpro_reference;
	        url += '/search';
	        url += '?language=' + lpdc.locale.toUpperCase();
	        url += '&hotelId=' + lpdc.availpro_hotelid;
	        url += '&arrivalDate=' + year + '-' + month + '-' + day;
	        url += '&nights=' + nights;
	        
	        jQuery('#booking-form-sticky #booking-link').attr('href', url);
					
					/*
	        jQuery('#booking-price').text('');
	        new Spinner({
						lines: 11,
						length: 4,
						width: 2,
						radius: 5,
						scale: 1,
						corners: 1,
						color: '#292929',
						opacity: 0.25,
						rotate: 0,
						direction: 1,
						speed: 1,
						trail: 60,
						fps: 20,
						zIndex: 2e9,
						className: 'spinner'
					}).spin(jQuery('#booking-price')[0]);
					//jQuery('#other-price').addClass('hidden');
					jQuery.ajax({
						type: 'POST',
						url: lpdc.ajax_uri,
						data: '&action=price_preview&nonce=' + lpdc.nonce_booking + '&'+jQuery('#booking-form-sticky').serialize(),
						success: function(data) {
							console.log(data);
							if (data.data.roomCats === jQuery('#booking-form-sticky select').val() && data.data.fromDate === jQuery('#booking-form-sticky input[name=fromDate]').val() && data.data.toDate === jQuery('#booking-form-sticky input[name=toDate]').val()) {
								if (data.success) {
									jQuery('#booking-price').text(data.data.bestPrice);
									//jQuery('#other-price .front').html('<span>' + data.data.bestOtherPrice + '</span>' + lpdc.strings.on_other_sites);
									//jQuery('#other-price').removeClass('hidden');
								} else {
									jQuery('#booking-price').text('-');
								}
							}
						},
						error: function(data) {
						  jQuery('#booking-price').text('-');
						}
		      });*/
        }
        function updateLink() {
	        var fromDateText = jQuery('#booking-form-mobile .value-from').val();
	        if (typeof fromDateText !== 'undefined' && fromDateText !== '') {
		        var year = parseInt(fromDateText.substring(0,4));
						var month = parseInt(fromDateText.substring(5,7));
						var day = parseInt(fromDateText.substring(8,10));
						var fromDate = new Date(year, month-1, day);
						var toDate = dateFromString(jQuery('#booking-form-mobile .value-to').val());
						var nights = (toDate.getTime() - fromDate.getTime()) / 24 / 60 / 60 / 1000;
						var adults = jQuery('#booking-form-mobile select[name=adults]').val();
						var children = parseInt(jQuery('#booking-form-mobile select[name=children]').val());
		        
		        var url = 'https://www.secure-hotel-booking.com/';
		        url += lpdc.availpro_name;
		        url += '/' + lpdc.availpro_reference;
		        url += '/search';
		        url += '?language=' + lpdc.locale.toUpperCase();
		        url += '&hotelId=' + lpdc.availpro_hotelid;
		        url += '&arrivalDate=' + year + '-' + month + '-' + day;
		        url += '&nights=' + nights;
		        url += '&selectedAdultCount=' + adults;
		        url += '&selectedChildCount=' + children;
		        
		        jQuery('#booking-form-mobile #search-link').attr('href', url);
	        }
        }
				jQuery.fn.select2.amd.require([
				  "select2/utils",
				  "select2/dropdown",
				  "select2/dropdown/attachContainer",
				  "select2/dropdown/closeOnSelect"
				], function (Utils, DropdownAdapter, AttachContainer, CloseOnSelect) {
				  var CustomAdapter = Utils.Decorate(
				    Utils.Decorate(
					    DropdownAdapter,
					    AttachContainer
					  ),
				    CloseOnSelect
				  );
				  
				  jQuery('#booking-form-sticky select').on('change', updatePrice).select2({
				    minimumResultsForSearch: Infinity,
				    dropdownAdapter: CustomAdapter
				  });
				  jQuery('#booking-form-mobile select').on('change', updateLink).select2({
				    minimumResultsForSearch: Infinity,
				    closeOnSelect: true
				  });
				  jQuery('.wpcf7-form select').each(function() {
						var firstOption = jQuery(this).find('option').first();
						var placeholder = firstOption.text();
						firstOption.val('').text('');
						jQuery(this).select2({
							placeholder: placeholder,
							minimumResultsForSearch: Infinity,
							dropdownAdapter: CustomAdapter
						});
					});
				});
				if (lpdc.locale === 'fr') {
					jQuery.datepicker.setDefaults( jQuery.datepicker.regional[ 'fr' ] );
				}
				var tomorrow = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
				function toggleDatepicker() {
	        var container = jQuery(this).parent();
	        var date = dateFromString(container.next('.booking-value').val());
	        // close other datepicker if opened
	        if (container.siblings('.booking-datepicker').first().hasClass('hasDatepicker')) {
		        var sibling = container.siblings('.booking-datepicker').first();
		        var siblingDate = dateFromString(sibling.next('.booking-value').val());
						var content = jQuery('<a>' + (container.hasClass('short-format') ? container.hasClass('booking-from') ? lpdc.strings.from : lpdc.strings.to : '') + jQuery.datepicker.formatDate(container.hasClass('short-format') ? 'd M' : 'd MM yy', siblingDate) + '</a>');
						content.on('click', toggleDatepicker);
		        sibling.datepicker('destroy').html(content);
	        }
	        // close current datepicker if opened
	        if (container.hasClass('hasDatepicker')) {
		        var content = jQuery('<a>' + (container.hasClass('short-format') ? container.hasClass('booking-from') ? lpdc.strings.from : lpdc.strings.to : '') + jQuery.datepicker.formatDate(container.hasClass('short-format') ? 'd M' : 'd MM yy', date) + '</a>');
			      content.on('click', toggleDatepicker);
		        container.datepicker('destroy').html(content);
		      // open current datepicker
	        } else {
		        container.datepicker({
		        	dateFormat: 'yy-mm-dd',
		        	defaultDate: date,
		        	minDate: container.hasClass('booking-from') ? new Date() : tomorrow,
		        	onSelect: function(dateText, instance) {
			        	var selectedDate = dateFromString(dateText);
			        	var content = jQuery('<a>' + (container.hasClass('short-format') ? container.hasClass('booking-from') ? lpdc.strings.from : lpdc.strings.to : '') + jQuery.datepicker.formatDate(container.hasClass('short-format') ? 'd M' : 'd MM yy', selectedDate) + '</a>');
			        	content.on('click', toggleDatepicker);
			        	jQuery(this).datepicker('destroy').html(content);
			        	jQuery(this).next('.booking-value').val(dateText);
			        	// update the other datepicker to have at least 1 night
			        	if (jQuery(this).hasClass('booking-from')) {
				        	var toDate = dateFromString(jQuery(this).parent().parent().find('.value-to').val());
				        	if (toDate.getTime() <= selectedDate.getTime()) {
					        	toDate = new Date(selectedDate.getTime() + 24 * 60 * 60 * 1000);
					        	var siblingBooking = jQuery(this).parent().parent().find('.booking-to');
					        	siblingBooking.find('a').text((siblingBooking.hasClass('short-format') ? lpdc.strings.to : '') + jQuery.datepicker.formatDate(siblingBooking.hasClass('short-format') ? 'd M' : 'd MM yy', toDate));
										jQuery(this).parent().parent().find('.value-to').val(jQuery.datepicker.formatDate('yy-mm-dd', toDate));
				        	}
			        	} else {
				        	var fromDate = dateFromString(jQuery(this).parent().parent().find('.value-from').val());
				        	if (selectedDate.getTime() <= fromDate.getTime()) {
					        	fromDate = new Date(selectedDate.getTime() - 24 * 60 * 60 * 1000);
					        	var siblingBooking = jQuery(this).parent().parent().find('.booking-from');
					        	siblingBooking.find('a').text((siblingBooking.hasClass('short-format') ? lpdc.strings.from : '') + jQuery.datepicker.formatDate(siblingBooking.hasClass('short-format') ? 'd M' : 'd MM yy', fromDate));
										jQuery(this).parent().parent().find('.value-from').val(jQuery.datepicker.formatDate('yy-mm-dd', fromDate));
				        	}
			        	}
			        	if (container.hasClass('short-format')) {
				        	updatePrice();
			        	} else {
				        	updateLink();
			        	}
		        	}
		        });
	        }
        }
        
				jQuery('.booking-from.short-format a').text(lpdc.strings.from + jQuery.datepicker.formatDate('d M', new Date()));
				jQuery('.booking-from.full-format a').text(jQuery.datepicker.formatDate('d MM yy', new Date()));
				jQuery('.value-from').val(jQuery.datepicker.formatDate('yy-mm-dd', new Date()));
        jQuery('.booking-to.short-format a').text(lpdc.strings.to + jQuery.datepicker.formatDate('d M', tomorrow));
        jQuery('.booking-to.full-format a').text(jQuery.datepicker.formatDate('d MM yy', tomorrow));
        jQuery('.value-to').val(jQuery.datepicker.formatDate('yy-mm-dd', tomorrow));
        jQuery('.booking-datepicker a').on('click', toggleDatepicker);
        updatePrice();
        updateLink();
        
				jQuery('input[type=date]')
          .attr('type', 'text')
          .datepicker({
          	dateFormat: 'yy-mm-dd'
          });
        jQuery('.slideshow-container').each(function() {
	        jQuery(this).magnificPopup({
					  delegate: 'a.slideshow-button',
					  type: 'image',
					  mainClass: 'mfp-with-zoom',
					  gallery: {
					    enabled: true,
					    tPrev: lpdc.locale === 'fr' ? 'Précédent' : 'Previous',
					    tNext: lpdc.locale === 'fr' ? 'Suivant' : 'Next',
					    tCounter: lpdc.locale === 'fr' ? '%curr% sur %total%' : '%curr% of %total%'
					  },
					  zoom: {
					    enabled: jQuery(this).hasClass('slideshow-with-zoom'),
							duration: 300,
					    easing: 'ease-in-out',
					  }
					});
        });
        jQuery('#input-newsletter').on('input', function() {
					jQuery('.newsletter-form span').remove();
					if (jQuery('#input-newsletter').val() !== '') {
						jQuery('.newsletter-form a').show();
					}
				});
				jQuery('.newsletter-form a').on('click', function() {
					if (!jQuery('.newsletter-form .spinner-container').length) {
						var target = jQuery('<div class="spinner-container"></div>').appendTo(jQuery('.newsletter-form'));
					  new Spinner({
							lines: 11,
							length: 4,
							width: 2,
							radius: 5,
							scale: 1,
							corners: 1,
							color: '#fff',
							opacity: 0.25,
							rotate: 0,
							direction: 1,
							speed: 1,
							trail: 60,
							fps: 20,
							zIndex: 2e9,
							className: 'spinner'
						}).spin(target.get(0));
						jQuery('.newsletter-form a').hide();
						jQuery.ajax({
							type: 'POST',
							url: lpdc.ajax_uri,
							data: '&action=subscribe_newsletter&nonce=' + lpdc.nonce_newsletter + '&email='+jQuery('#input-newsletter').val(),
							success: function(data) {
								jQuery('.newsletter-form').append('<span class="success"><i class="fa fa-check"></i></span>');
							},
							error: function(data) {
							  jQuery('.newsletter-form').append('<span class="error"><i class="fa fa-times"></i></span>');
							  setTimeout(function() {
								  jQuery('.newsletter-form span.error').remove();
								  jQuery('.newsletter-form a').show();
							  }, 3000);
							},
							complete: function() {
								jQuery('.newsletter-form .spinner-container').remove();
							}
			      });
					}
				});
      },
      finalize: function() {
        
      }
    },
    // Home page
    'home': {
      init: function() {
        jQuery( '.slider-container' ).each(function() {
	        jQuery( this ).unslider({
		        autoplay: true,
		        delay: jQuery( this ).attr( 'data-speed' ),
		        nav: false,
		        arrows: false,
		        infinite: true
	        });
        });
      },
      finalize: function() {
        
      }
    },
    // Contact
    'contact': {
      init: function() {
        jQuery('.wpcf7-form-control-wrap input, .wpcf7-form-control-wrap select').each(function() {
					var parent = jQuery(this).parent();
					var classList = jQuery(this).attr('class').split(/\s+/);
					for (i = 0; i < classList.length; i++) {
						if (classList[i].substring(0, 5) === 'icon-') {
					  	parent.addClass(classList[i]);
					  	jQuery(this).removeClass(classList[i]);
						}
					}
				});
				jQuery('input.counter').each(function() {
					var input = jQuery(this);
					var wrapper = jQuery('<span class="wpcf7-form-control-wrap counter"></span>').insertBefore(input);
					input.remove().appendTo(wrapper);
					var counter = jQuery('<div class="counter-container"></div>');
					var text = jQuery('<span></span>').appendTo(counter);
					var min = '2';
					var max = '99';
					var value = null;
					var classList = jQuery(this).attr('class').split(/\s+/);
					for (i = 0; i < classList.length; i++) {
						if (classList[i] === 'left' || classList[i] === 'right') {
					  	counter.addClass(classList[i]);
					  	jQuery(this).removeClass(classList[i]);
						} else if (classList[i].substring(0, 5) === 'icon-') {
					  	wrapper.addClass(classList[i]);
					  	jQuery(this).removeClass(classList[i]);
						} else if (classList[i].substring(0, 4) === 'min-') {
							min = classList[i].substring(4);
							counter.attr('data-min', min);
						} else if (classList[i].substring(0, 4) === 'max-') {
							max = classList[i].substring(4);
							counter.attr('data-max', max);
						} else if (classList[i].substring(0, 8) === 'default-') {
							value = classList[i].substring(8);
						}
					}
					if (value === null) {
						value = min;
					}
					input.val(value);
					counter.attr('data-value', value);
					text.text(value + ' ' + input.attr('name'));
					var plus = jQuery('<a class="plus">+</a>').appendTo(counter);
					plus.on('click', function() {
						var value = parseInt(counter.attr('data-value'));
						var max = parseInt(counter.attr('data-max'));
						value += 1;
						if (value > max) {
							value = max;
						}
						input.val(value);
						counter.attr('data-value', value);
						text.text(value + ' ' + input.attr('name'));
					});
					var minus = jQuery('<a class="minus">-</a>').appendTo(counter);
					minus.on('click', function() {
						var value = parseInt(counter.attr('data-value'));
						var min = parseInt(counter.attr('data-min'));
						value -= 1;
						if (value < min) {
							value = min;
						}
						input.val(value);
						counter.attr('data-value', value);
						text.text(value + ' ' + input.attr('name'));
					});
					input.before(counter);
				});
      },
      finalize: function() {
        
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

jQuery(window).on("load", function() {
    jQuery('.category-box .title-image, .room-box .title-image, .single-hall-box .title-image').each(function() {
			jQuery(this).css('margin-bottom', '-' + Math.floor(jQuery(this).outerHeight() * 0.7) + 'px');
		});
		jQuery('.tourism-section').masonry({
      itemSelector: '.tourism-item',
      gutter: 18
    });
    jQuery('.gallery-section').masonry({
      itemSelector: '.gallery-item',
      gutter: 18
    });
});